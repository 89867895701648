<template>
  <!-- menu utama -->
  <div class="container menu">
    <div class="row mb-4">
      <div class="col-lg-4 mx-auto text-center">
        <button
          class="btn bg-main text-uppercase"
          @click="$router.push('/pengaduan/create')"
          style="font-weight: bold; width: 100%; border-radius: 20px"
        >
          Buat Pengaduan
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <a
          href="https://webgis.sijaga.kabbdg.com/"
        >
          <img src="@/assets/pengaduan/assets/images/gis.png" width="150px" height="150px"
        /></a>
        <h3>GIS SI-JAGA</h3>
        <p>Sistem Informasi Geografis Irigasi Kab Bandung</p>
      </div>
      <div class="col" @click="$router.push('/pengaduan/list')">
        <img src="@/assets/pengaduan/assets/images/sijaga1.png" width="150px" height="150px" />
        <h3>Laporan Pengaduan</h3>
        <p>Pengaduan terpadu</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.col {
  text-align: center !important;
}

.menu {
  padding-top: 40px;
}

h3 {
  padding-top: 20px;
  color: #4d504e;
}

p {
  color: #94a2b3;
}
</style>

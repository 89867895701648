<template>
  <div>
    <Banner />
    <Main />
    <!-- <RecentComplaint /> -->
  </div>
</template>

<script>
import Banner from "@/views/Home/Banner.vue";
import Main from "@/views/Home/Main.vue";
import RecentComplaint from "@/views/Home/RecentComplaint.vue";

export default {
  components: {
    Banner,
    Main,
    RecentComplaint,
  },
};
</script>


<style>
.bg-main {
  background-color: #036163 !important;
  color: white !important;
}
.text-main {
  color: #036163 !important;
}
</style>
